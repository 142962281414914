import React, { useState } from "react";
import BIMObjectCreation from "../../components/_Landing/BIMObjectCreation";
import SEO from "../../components/Seo";

const Services = ({ location }) => {
  const [showModal, setShowModal] = useState(true);
  const [category, setCategory] = useState<string | null>(null);
  const pathname = location.pathname;
  return <BIMObjectCreation />;
};

export default Services;

export const Head = () => (
  <SEO
    title={"BIM Services in Singapore | CAD to BIM Conversion"}
    description="Convert CAD & PDF drawings into detailed BIM models
                  in Revit, ArchiCAD, OpenBuildings, and more."
    pathname={"/services/bim-modelling"}
  />
);
