import React from "react";
import { FaFileUpload, FaPeopleArrows } from "react-icons/fa";
import { MdRateReview } from "react-icons/md";
import { FaBuildingCircleCheck } from "react-icons/fa6";
import { IoShieldCheckmarkSharp } from "react-icons/io5";
import { GiCrane } from "react-icons/gi";

export const WhyData = [
  {
    id: 1,
    img: "",
    icon: <FaBuildingCircleCheck size={30} />,
    title: "BIM Submissions",
    description:
      "Create clash-free BIM models to obtain regulatory approvals and permits via Corenet",
  },
  {
    id: 2,
    img: "./4d-bim-black.svg",
    icon: <IoShieldCheckmarkSharp size={30} />,
    title: "Design Analysis",
    description:
      "Ensure design structure meets building code and environmental standards.",
  },
  {
    id: 3,
    img: "./5d-bim-black.svg",
    icon: <GiCrane size={30} />,
    title: "Build Visualization",
    description:
      "Visualize project buildout with virtual design & construction by linking schedule to BIM",
  },
];

export const HowData = [
  {
    id: 1,
    img: "",
    icon: <FaFileUpload size={30} />,
    title: "Step 1: Share Files",
    description:
      "Share .pdf, .dwg, .dgn, and other CAD formats over a secure file submission link.",
  },
  {
    id: 2,
    img: "./4d-bim-black.svg",
    icon: <FaPeopleArrows size={30} />,
    title: "Step 2: Project Assignment",
    description:
      "Our BIM experts will review your project and allocate it to the right team.",
  },
  {
    id: 3,
    img: "./5d-bim-black.svg",
    icon: <MdRateReview size={30} />,
    title: "Step 3: BIM Delivery",
    description:
      "Our BIM Team delivers your project within the agreed timeline.",
  },
];

export const faq = [
  {
    id: 1,
    section: "bim-formats",
    question: "What are the types of CAD & BIM formats you support?",
    answer:
      "The most common CAD formats in the built-environment context is autocad (.dwg) and microstation (.dgn). We are able to support to the most common BIM formats such as Revit (.rvt), Archicad (.pln), and IFC.",
  },
  {
    id: 3,
    question: "Is there onsite support?",
    answer: "Yes, we work closely with your project team to ensure that CAD & BIM models are created up to the project specifications.",
  },
  {
    id: 4,
    question: "What files do I need to provide?",
    answer:
      "Provide us with the 2D CAD drawings, PDFs, and any other relevant files that you have to create the BIM models. For clash coordination and sequencing, please provide the architectural and strucutral BIM models.",
  },
  {
    id: 4,
    section: "corenetx",
    question: "What is required for CorenetX submissions?",
    answer:
      "CorenetX, formerly known as Corenet 2.0, is a government permitting portal introduced by BCA that mandates BIM model submissions in the openBIM format. We ensure that all required IFC-SG parameters are accurately embedded within the BIM model to facilitate smooth and successful submissions.",
  },
];
